<template>
  <input
    type="checkbox"
    id="my-modal"
    class="modal-toggle"
    v-model="showModal"
  />
  <div
    class="modal modal-bottom sm:modal-middle"
    @click="closeModal"
  >
    <div
      class="modal-box not-prose"
      @click.stop
    >

      <div>

        <h3 class="font-bold text-lg my-5">Log in or sign up</h3>

        <p
          v-if="props.modalData.mode === 'default'"
          class="text-sm my-5"
        >
          Authentication is required so that we can email you your results, and so that you can delete your data later
          if
          you so wish.
        </p>

        <div class="my-7">
          <!-- <div
            class="w-full"
            id="g_id_onload"
            data-client_id="514573038523-msvn8v9f4rbvn9f5pq8rp8m5559m0h6e.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-callback="handleGoogleCredentialResponse"
            data-itp_support="true"
          >
          </div>

          <div
            class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="filled_black"
            data-text="continue_with"
            data-size="large"
            data-logo_alignment="left"
          >
          </div> -->
          <button
            class="btn btn-google w-full"
            type="button"
            @click="authWithGoogle"
          >
            <span
              class="loading loading-spinner"
              v-if="googleAuth.status === 'loading'"
            ></span>
            <img
              src="@/assets/img/google-logo.svg"
              alt="Logo"
              width="25"
              class="m-0"
            >
            Continue with Google
          </button>

        </div>

        <p
          class="mt-3"
          v-if="props.modalData.mode === 'previewGeneration'"
        >
          Your previews will take time to generate, we'll email you when they're ready!
        </p>

        <p
          class="mt-3"
          v-if="props.modalData.mode === 'imageUpload'"
        >
          Login is required for image upload so that you can easily delete your images later.
        </p>

        <p
          class="mt-3"
          v-if="props.modalData.mode === 'pageRequiresAuth'"
        >
          You need to log in to view this page
        </p>

        <div class="relative flex h-10 items-center justify-center mt-5">
          <hr class=" h-[1px] grow">
          <span class=" w-16 shrink-0 text-center">or</span>
          <hr class=" h-[1px] grow">
        </div>

        <form
          @submit.prevent="doAuth"
          class="w-full my-2"
        >

          <div class="flex flex-col">

            <div v-if="formData.error">
              <p class="text-error text-center">
                {{formData.error}}
              </p>
            </div>

            <div
              class="form-control w-full"
              v-else-if="['waiting', 'loading'].includes(formData.status)"
            >

              <label
                class="label"
                for="email"
              >
                <span class="label-text">Continue with email</span>
              </label>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                class="input w-full border"
                autocomplete="email"
                v-model="formData.email"
                ref="emailInput"
              />

              <div class="w-full my-3">
                <button
                  type="submit"
                  class="btn btn-primary px-3 w-full"
                  :disabled="disableSubmitButton"
                >
                  <span
                    class="loading loading-spinner"
                    v-if="formData.status === 'loading'"
                  ></span>
                  send secure one-time link
                </button>
              </div>
            </div>

            <!-- <div class="form-control w-full mt-3">

                <label class="label" for="password">
                  <span class="label-text">Password</span>
                  <span class="label-text-alt">Min 6 chars</span>
                </label>
                <input id="password" type="password" placeholder="password" class="input w-full max-w-lg bg-base-200" v-model="formData.password"/>

              </div> -->

            <!-- <div class="mt-3">
                <p class="w-full" v-if="!formData.error && formData.message">
                  {{formData.message}}
                </p>
                <p class="w-full text-error" v-if="formData.error">
                  {{formData.error}}
                </p>
              </div> -->


            <div
              v-else-if="formData.status=='complete'"
              class="text-center px-3 py-2"
            >
              <p>
                We've sent a secure one-time link to <strong>
                  {{formData.email}}
                </strong>
                <br>
                Click it to log in
              </p>
            </div>

          </div>

        </form>

        <div class="mt-3">

          <p class="muted text-xs">
            We do not send spam, product emails are opt-in only, and your email is never shared with any third party.
            <br>

            <br>
            You can instantly delete your account at any time.
          </p>
        </div>

      </div>

    </div>
  </div>
</template>

<style scoped></style>

<script setup>

const config = useRuntimeConfig().public
const props = defineProps({
  modalData: {
    type: Object,
    default: {
      mode: 'default'
    }
  }
})

const emit = defineEmits(['closeModal'])

const showModal = ref(false)
const { $supabase } = useNuxtApp()
const formData = ref({
  'email': '',
  'password': '',
  'status': 'waiting',
  'error': null,
  'mode': 'waitingEmail'
})
const googleAuth = ref({
  'status': 'waiting',
  'error': null,
})
const emailInput = ref(null)

onMounted(() => {
  setTimeout(() => {
    showModal.value = true
    // setTimeout(() => {
    //   emailInput.value.focus()
    // }, 50);
  }, 50)

    // initialize Google Sign in  
  google.accounts.id.initialize({
    client_id: config.GOOGLE_CLIENT_ID,
    callback: handleGoogleCredentialResponse, //method to run after user clicks the Google sign in button
    context: 'signin'
  })

  // render button
  // google.accounts.id.renderButton(
  //   document.getElementById('g_id_onload'),
  //   {
  //     type: 'standard',
  //     size: 'large',
  //     text: 'signin_with',
  //     shape: 'rectangular',
  //     logo_alignment: 'center',
  //     width: 250
  //   }
  // )
})

function closeModal() {

  if (props.modalData.mode === 'pageRequiresAuth') {
    // don't let them close it
    return
  }

  console.log("Closing auth modal")
  showModal.value = false
  setTimeout(() => {
    emit('closeModal')
    // showModal.value = true
  }, 200)
}

const disableSubmitButton = computed(() => {

  if (formData.value.status === 'loading') return true

  var re = /\S+@\S+\.\S+/;
  return !re.test(formData.value.email);
})



async function authWithGoogle() {

  console.log("logging in with Google")

  formData.value.error = null

  googleAuth.value.status = 'loading'

  try {
    
    const { data, error } = await $supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        // queryParams: {
        //   access_type: 'offline',
        //   prompt: 'consent',
        // },
        redirectTo: window.location.href
      },
    })
    
    if (error) {
      throw error
    }

    console.log('google data:', data);

  } catch (error) {
    console.log("Error requesting link:", error)
    toast.error("Error: " + error?.message || JSON.stringify(error))
    formData.value.error = error
  } finally {
    formData.value.status = "waiting"
  }

}

async function doAuth() {

  formData.value.error = null

  console.log("doing auth..., formData:", formData.value)

  formData.value.status = 'loading'

  let authRequestData = {
    email: formData.value.email,
    options: {
      emailRedirectTo: window.location.href
    }
  }

  console.log('authRequestData:', authRequestData);

  try {
  
    const { data, error } = await $supabase.auth.signInWithOtp(authRequestData)

    if (error) throw error

    console.log("Link sent successfully")

    formData.value.status = "complete"

  } catch (error) {
    console.log("Error requesting link:", error)
    formData.value.error = error
  } 
}

</script>